export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Dollmare",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://b.gamesdizi.com/ed/dollmare-demo/",
    domain: "dollmare.com",
    gaId: "G-E4Y8XX3RM8",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
